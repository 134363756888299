/**
 * 数据分析-楼盘信息统计
 * luxinwen
 * 2023-04-26
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_ANALYSIS_PROJECT_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.buildingNo" placeholder="请输入项目ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.name" placeholder="请输入项目名称" clearable />
          </FormItem>
          <FormItem>
            <DatePicker class="width-m" v-model="formData.recommendTime" type="datetimerange" placeholder="请选择时间" />
          </FormItem>
          <!-- <FormItem>
            <Cascader v-model="formData.citys" :data="provinceList" :load-data="loadCity" placeholder="请选择项目城市" clearable></Cascader>
          </FormItem> -->
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
            <Button type="primary" @click="exportData" v-grant="'OSP_ANALYSIS_PROJECT_EXPORT'">导出</Button>
            <Button @click="showRecord" v-grant="'OSP_ANALYSIS_PROJECT_EXPORT'">查看导出记录</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.masterImage" class="img-preview" @click="previewImage(params.row.masterImage)">
          </template>
          <template slot-scope="params" slot="cityName">
            <span>{{ (allCityList.find(item => item.regionId === params.row.cityId) || {}).regionName }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_ANALYSIS_PROJECT_VIEW'">查看</Button>
          </template>
        </sp-table>
      </div>
      <Modal v-model="recordDisplay" width="80%" title="导出记录" footer-hide :mask-closable="false">
        <sp-table :columns="recordColumns" :data="recordDataList" :loading="recordLoading" :total="recordPager.total" :page="recordPager.pageNum" :page-size="recordPager.pageSize" @change="changeRecordPage">
        </sp-table>
      </Modal>
    </div>

    <modal-preview v-model="previewImageUrl"></modal-preview>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {
          recommendTime: [],
          buildingNo: '', // 项目ID
          name: '', // 项目名称
          // citys: []
        },
        tableColumns: [
          {
            title: '项目ID',
            key: 'buildingNo'
          },
          {
            title: '项目主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '项目名称',
            key: 'name'
          },
          {
            title: '项目城市',
            slot: 'cityName'
          },
          {
            title: '分享总数',
            key: 'shareCount'
          },
          {
            title: '查看总数',
            key: 'allCount'
          },
          {
            title: '扫码总人数',
            key: 'scanNum'
          },
          {
            title: '扫码注册',
            key: 'regNum'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        recordDisplay: false,
        recordColumns: [
          {
            type: 'index',
            title: '序号',
            width: 100,
            align: 'center'
          },
          {
            title: '文件名',
            key: 'fileName'
          },
          {
            title: '操作人',
            key: 'operator'
          },
          {
            title: '导出时间',
            key: 'createdTime'
          },
          {
            title: '操作',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  attrs: {
                    href: params.row.downloadUrl,
                    target: '_blank'
                  }
                }, '请下载')
              ]);
            }
          }
        ],
        recordDataList: [],
        recordLoading: false,
        recordPager: {
          total: 0,
          pageNum: 1,
          pageSize: 10
        },
        allCityList: [],
        provinceList: [],
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_ANALYSIS_PROJECT_VIEW];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getCityRegionAllList();
      this.getData();
      this.getProvinceRegionList();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (this.formData.recommendTime.length > 1) {
          data.queryStartDate = this.formatDate(this.formData.recommendTime[0]);
          data.queryEndDate = this.formatDate(this.formData.recommendTime[1]);
        }
        // if (data.citys.length > 0) {
        //   data.provinceId = data.citys[0];
        //   data.cityId = data.citys[1];
        // }
        // delete data.citys;
        this.$axios({
          url: this.$api.analysis.queryBuildingInfoStatictics,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取所有城市列表
       */
      getCityRegionAllList() {
        this.$axios({
          url: this.$api.region.queryCityRegionAllList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          this.allCityList = data || [];
        });
      },
      /**
       * 查询省份列表
       */
      getProvinceRegionList() {
        this.$axios({
          url: this.$api.region.queryProvinceRegionList,
          data: {
            serviceType: 1
          }
        }).then(data => {
          (data || []).forEach(item => {
            this.provinceList.push({
              value: item.regionId,
              label: item.regionName,
              children: [],
              loading: false
            });
          });
        });
      },
      /**
       * 获取城市区域列表
       */
      loadCity(item, callback) {
        this.$axios({
          url: this.$api.region.queryCityRegionList,
          data: {
            regionParentId: item.value,
            serviceType: 1
          },
          beforeSend: () => {
            item.loading = true;
          },
          complete: () => {
            item.loading = false;
          }
        }).then(data => {
          (data || []).forEach(city => {
            item.children.push({
              value: city.regionId,
              label: city.regionName
            });
          });
          callback && callback();
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看
       */
      showData(row) {
        this.$router.push({
          name: 'analysisProjectDetail',
          query: {
            id: row.id
          }
        });
      },
      /**
       * 预览
       */
      previewImage(url) {
        this.previewImageUrl = url;
      },
      /**
       * 查看导出记录
       */
      showRecord() {
        this.recordDisplay = true;
        this.getRecordData();
      },
      /**
       * 导出
       */
      exportData() {
        let data = Object.assign({}, this.formData);
        if (this.formData.recommendTime.length > 1) {
          data.queryStartDate = this.formatDate(this.formData.recommendTime[0]);
          data.queryEndDate = this.formatDate(this.formData.recommendTime[1]);
        }
        this.$axios({
          url: this.$api.analysis.exportTBuildingInfoStaticticsVO,
          data,
          loading: true
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '操作成功，请稍后查看导出记录'
          });
        });
      },
      /**
       * 获取导出记录数据
       */
      getRecordData() {
        let params = {
          operationType: 'BUILDING_COUNT',
          pageNum: this.recordPager.pageNum,
          pageSize: this.recordPager.pageSize
        };
        this.$axios({
          url: this.$api.analysis.exportRecordList,
          data: params,
          beforeSend: () => {
            this.recordLoading = true;
          },
          complete: () => {
            this.recordLoading = false;
          }
        }).then(data => {
          this.recordDataList = data.list || [];
          this.recordPager.total = data.total || 0;
        });
      },
      /**
       * 切换页码或每条页数
       */
      changeRecordPage(pager) {
        this.recordPager.pageNum = pager.page;
        this.recordPager.pageSize = pager.pageSize;
        this.getRecordData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>